<template>

    <nav id="nav-container">

        <div id="nav-menu">

            <label id="nbMenuButom" for="checkbox">
                <img :src="img_menu" :alt="alt_menu" id="img_menu">
            </label>

            <div class="divNavContainer">

                <div class="divNavLogo">
                    <router-link to="/" id="logo-url">
                        <img :src="logo" :alt="alt" id="logo">
                    </router-link>
                </div>
                <div class="divNavMenuItems">
                    <div v-for="Mn_Itens in menu_itens" v-bind:key="Mn_Itens.id" class="divNavItem">
                        <router-link class="nav-menu-item" v-bind:to="Mn_Itens.rtr_name">{{Mn_Itens.m_item}}
                        </router-link>
                    </div>
                </div>


            </div>
        </div>

        <div class="divNavV">

            <input class="inputMenuV" type="checkbox" id="checkbox">
            <div class="sidebar divMenuV" id="sidebar">
                <router-link class="nav-menu-itemV" v-for="Mn_Itens in menu_itens" v-bind:key="Mn_Itens.id"
                    v-bind:to="Mn_Itens.rtr_name" v-on:click="Ocultar">{{Mn_Itens.m_item}}</router-link>
            </div>

        </div>
    </nav>

</template>

<script>
export default {
    name: "navbar",
    props: ["logo", "alt", "img_menu", "alt_menu"],
    data() {
        return {
            menu_itens: [
                { id: 1, m_item: 'Home', m_link: "#", rtr_name: "/" },
                { id: 2, m_item: 'Quem Somos', m_link: "#", rtr_name: "/sobre" },
                /*{ id: 3, m_item: 'Nossos Serviços', m_link: "#", rtr_name: "/ocupation" },*/
                { id: 4, m_item: 'Parceiros', m_link: "#", rtr_name: "/parceiros" },
                /*{ id: 5, m_item: 'Fale Conosco', m_link: "#", rtr_name: "/tellus" },*/
            ],
        }
    },
    methods: {

        Ocultar: function () {
            document.getElementById("checkbox").checked = false;
        },

    },
}
</script>