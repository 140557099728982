<template>
    <section id="banner-c">
        <Banner />
    </section>
    <section>
        <Lat_vert_Items />
    </section>
    <section>
        <Represent />
    </section>
    <section>
        <Partners />
    </section>
</template>

<script>
import Banner from '@/components/banner.vue';
import Lat_vert_Items from '@/components/lat_vert_Items.vue';
import Represent from '../components/represent.vue';
import Partners from '@/components/partners.vue';
export default {
    name: "Home",
    components: { Banner, Lat_vert_Items, Represent, Partners },   
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
    /*connect() {

      if (global.dbpool && global.dbpool.state !== 'disconnected')
        return global.dbpool;

      const mysql = require('mysql/promise');

      const dbpool = mysql.createConnection({
        host: '192.168.15.100',
        user: 'CSYS',
        database: 'csys',
        password: '@DMcompany998877'
      });

      console.log("Conectou");
      global.dbpool = dbpool;
      return dbpool;
    }*/
  } 
}
</script>
