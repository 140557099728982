<template>
    <div id="footer">
        <p><b>Companysys Soluções &copy; 2022</b></p>
    </div>
</template>

<script>
    export default {
        name:"Footer"
    }
</script>