<template>
    <div id="partners_container">
        <div class="cards-square-list">

            <div v-for="part_item in partners_itens" v-bind:key="part_item.id" class="card-square">
                <div v-bind:class="[part_item.class1, part_item.class2]">
                    <img class="crd_image" :src="part_item.img_item" :alt="part_item.card_title" />
                </div>
                <div class="card_title title-white">
                    <p>{{part_item.card_title}}</p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: "partners",
    data() {
        return {
            partners_itens: [
                /*{ id: 1, img_item: '../img/partners/stone-logo.svg', card_title: 'Stone', class1:'card_image', class2:'img1'},*/
                { id: 2, img_item: '../img/partners/bematech-logo.svg', card_title: 'Stone', class1:'card_image', class2:'img2'},
                { id: 3, img_item: '../img/partners/bndes-banco-nacional-de-desenvolvimento.png', card_title: 'Stone', class1:'card_image', class2:'img3'},
                { id: 4, img_item: '../img/partners/logo-eztech.png', card_title: 'Stone', class1:'card_image', class2:'img4'},
                { id: 5, img_item: '../img/partners/sos-logo.png', card_title: 'Stone', class1:'card_image', class2:'img5'},
            ],
        }
    },

}
</script>