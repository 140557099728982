<template>

    <div id="represent_container">
        <div id="represent_container_inter">
            <div id="represent_img_inter">
                <span class="rep_img_window" style="">
                    <img class="rep_img" src= "../components/img/represent/sos-logo.png" alt="">
                </span>
            </div> 
            <div id="represent_text_inter">
                <p id="rep_sos">
                    <span class="rep_text">
                        <strong>A <span  class="company">COMPANY</span><span class="sys">SYS</span></strong>
                        é uma empresa representante da <span  class="sos-nome">SOS </span><span class="system-sos">SYSTEM</span> e serviços de infra estrutura de Rede e Tecnologia.
                    </span>
                </p>
            </div> 
        </div>
    </div>

</template>

<script>

export default {
    name: "represent",
    data() {
        return {
            
        }
    },
    methods: {

    },
    mounted() {

    },
}

</script>

<style scoped>
    
</style>