<template>

  <header>
    <Navbar :logo=logo_src :alt="app_name" :img_menu=logo_menu_src :alt_menu="menu_image" />
  </header>

  <main id="router-container">
    <section class="main-container">
      <router-view />
    </section>
  </main>

  <footer>
    <Footer />
  </footer>

</template>

<script>
import Navbar from './components/navbar.vue';
import Footer from './components/footer.vue';
/*import db from '@/db/db';*/

export default {
  name: "App",
  components: { Navbar, Footer },
  data() {
    return {
      logo_src: "../img/Logo.png",
      logo_menu_src: "../img/menu-stripes.png",
      app_name: "company_site"
    }
  },
  mounted() {
    /*db = require('@/db/db');*/
  },
}

</script>

<style scoped>
@import "./styles/style.css";
@import "./styles/sty_mn_vert.css";
</style>

