<template>
    
        <!--id="banner_itens"-->
        <section class="carousel">
            
                    <div>
                        <Transition name="slide-fade">
                            <div v-if="TUC1" class="slide-card">
                                <h1 class="slide-card-tittle" >AUTOMAÇÃO COMERCIAL</h1>
                                <h3 class="slide-card-text">Amplie os resultados da sua empresa com um sistema de
                                    gestão completo e diversos equipamentos de automação das melhores marcas.</h3>
                            </div>
                        </Transition>
                    </div>
            
            
                    <div>
                        <Transition name="slide-fade">
                            <div v-if="TUC2" class="slide-card">
                                <h1 class="slide-card-tittle">SISTEMAS DE GESTÃO</h1>
                                <h3 class="slide-card-text">Controle sua empresa, estoque, emita notas e visualize
                                    seus resultados com o RP que atende suas necessidades e suporte técnico próprio.
                                </h3>
                            </div>
                        </Transition>
                    </div>            
            
                    <div>
                        <Transition name="slide-fade">
                            <div v-if="TUC3" class="slide-card">
                                <h1 class="slide-card-tittle">SEGURANÇA E TELECOM</h1>
                                <h3 class="slide-card-text">Proteja seu patrimônio e amplie a qualidade da sua
                                    comunicação com as melhores soluções em telefonia, interfonia, CFTV, alarmes e rede.
                                </h3>
                            </div>
                        </Transition>
                    </div>
                    
        </section>

</template>

<script>
export default {
    name: "banner_carousel",
    data() {
        return {
            show: true,
            MovCount: 0,
            TUC1: false,
            TUC2: false,
            TUC3: false,
        };
    },
    mounted() {
        setInterval(() => this.setTime(), 1000)
    },
    methods: {

        setTime() {

            if (this.MovCount === 0) {
                this.TUC1 = true;
            }
            if (this.MovCount === 8) {
                this.TUC1 = false;
            }
            if (this.MovCount === 9) {
                this.TUC2 = true;
            }
            if (this.MovCount === 17) {
                this.TUC2 = false;
            }
            if (this.MovCount === 18) {
                this.TUC3 = true;
            }

            this.MovCount = this.MovCount + 1;

            if (this.MovCount === 26) {
                this.TUC3 = false;
                this.MovCount = 0;
            }

        },
    }
}
</script>

<style scoped>
.slide-fade-enter-active {
    transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
    transition: all 1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
</style>