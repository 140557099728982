import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/sobre',
    name: 'sobre',
    component: () => import('@/views/About.vue')
  },
  {
    path: '/parceiros',
    name: 'parceiros',
    component: () => import('@/views/Partners.vue')
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: () => import('@/views/not_found.vue')
  },
]

var anonima = function () {

  switch (window.location.pathname) {
    case '/': {
      break;
    }
    case '/sobre': {
      break;
    }
    case '/parceiros': {
      break;
    }
    case '/notfound': {
      setTimeout(function () {
        window.location.href = "/";
      }, 3000);
      break;
    }
    default:
      {
        window.location.href = "/notfound";        

        break;
      }
  }

}

anonima();

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
