<template>

    <div>
        <p v-if="false">Compontente de mensagem</p>
        <div id="LateralItens">
            <div id="LateralItens-fog">
                <div class="container">

                    <div v-for="card_item in card_itens" v-bind:key="card_item.id" class="card">
                        <div class="box">
                            <div class="content">
                                <div class="flex-items-tittle">
                                    <span class="q_circle_inner">
                                        <span class="q_circle_inner2">
                                            <img class="q_image_in_circle" :src="card_item.card_img" :alt="card_item.card_title">
                                        </span>
                                    </span>
                                    <!--<h1>SISTEMA DE GESTÃO</h1>-->
                                    <!--<h2 class="flex-items">SISTEMA DE GESTÃO</h2>-->
                                    <h3 class="flex-items">{{card_item.card_title}}</h3>
                                </div>
                                <div class="flex-items-info">
                                    <p class="flex-items">{{card_item.card_text}}</p>
                                </div>
                                <a v-if="false" href="#">Read More</a>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: "Lat_Vert_Items",
    data() {
        return {
            card_itens: [
                { id: 1, card_img: '../img/destaque-sistema.png', card_title: 'SISTEMA DE GESTÃO', card_text: 'Sistemas de gestão completos, com módulos específicos para cada segmento.' },
                { id: 2, card_img: '../img/revenda-destaque-automacao.png', card_title: 'AUTOMAÇÃO COMERCIAL', card_text: 'Tudo o que o sua empresa precisa, em um só lugar.' },
                { id: 3, card_img: '../img/revenda-destaque-seguranca.png', card_title: 'SEGURANÇA E TELECOM', card_text: 'Os melhores produtos em alarme, telefonia, e infraestrutura de redes.' },
                { id: 4, card_img: '../img/home-20-process-04.png', card_title: 'CONSULTORIA DE TI', card_text: 'A COMPANYSYS possui um departamento especializado para auxiliar os executivos na tomada de decisões.' },
                { id: 5, card_img: '../img/home-20-process-05.png', card_title: 'SUPORTE', card_text: 'A COMPANYSYS dispõe de suporte remoto, em campo, agendado. Entre em contato conosco ou solicite uma proposta.' },
            ],
        };
    },
}

</script>