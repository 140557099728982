<template>
    <div id="main-banner">
        <Banner_carousel/>
    </div>
</template>

<script>
import Banner_carousel from './banner_carousel.vue';
export default {
    name: "banner",
    components: { Banner_carousel }
}
</script>

<style scoped>
    
</style>